











































import { Vue, Component, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { PageHeader } from "@/components/al/pageHeader";
import { CommentsList } from "@/components/comments/commentsList";
import { CommentForm } from "@/components/comments/commentForm";

@Component({
    components: {
        "al-page-header": PageHeader,
        CommentsList,
        CommentForm,
    },

    data() {
        return {
            commentsList: [],
        };
    },
})
export default class Comments extends Vue {
    @Getter("getUser") getUser: any;

    actived: number = 0; //0是原文,1是英文,2是中文
    /**设置项目语言版本导航actived */
    setNavActived(index: number) {
        this.actived = index;
    }
    /**项目语言版本导航点击事件 */
    handleNavItem(index: number | string) {
        this.setNavActived(Number(index));
    }

    /**获取评论列表 */
    getComments() {
        const id = this.$route.params.pid;
        const getOpt = this.$api.apiList.getComments;
        const opt = getOpt(id);

        this.$axios({
            method: opt.method,
            url: opt.url,
        }).then((res: any) => {
            this.$data.commentsList = res.data.data;
        });
    }

    /**提交评论 */
    async setComments(comment: any, callback: any) {
        const id = this.$route.params.pid;
        const getOpt = this.$api.apiList.uploadComments;
        const opt = getOpt(id);

        const res = await this.$axios({
            method: opt.method,
            url: opt.url,
            headers: opt.headers,
            data: { comment_root: comment },
        })
            .then((res: any) => {
                this.$data.commentsList.push(res.data.comment);
                return { status: 0, msg: "" };
            })
            .catch((err: any) => {
                if (
                    Object.keys(err.response.data).findIndex(
                        (val) => val == "errors"
                    ) != -1
                ) {
                    return {
                        status: 1,
                        msg: err.response.data.errors.comment_root[0],
                    };
                } else if (
                    Object.keys(err.response.data).findIndex(
                        (val) => val == "comment_root"
                    ) != -1
                ) {
                    return {
                        status: 1,
                        msg: err.response.data.comment_root,
                    };
                } else
                    return {
                        status: err.response.status,
                        msg: err.response.statusText,
                    };
            });

        callback(res);
    }

    remaining = 0; //当前项目当前帐号剩余可评论数
    /**计算当前项目当前帐号剩余可评论数 */
    getRemaining(list: []) {
        const uid = this.getUser("id");
        const object = list;
        const limit = 3; //限制条数
        let num = 0; //当前条数

        for (const key in object) {
            if (Object.prototype.hasOwnProperty.call(object, key)) {
                const element = object[key];
                if ((element as any).user_id == uid) num++;
            }
        }

        this.remaining = limit - num < 0 ? 0 : limit - num;
    }

    /**监听评论列表,有变化时重新计算剩余可评论数 */
    @Watch("commentsList")
    onChange(nval: any, oval: any) {
        if (nval.length > 0) this.getRemaining(nval);
        else this.remaining = 3;

        console.log(this.remaining);
    }

    mounted() {
        this.getComments();
    }
}
