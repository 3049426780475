




















import { Vue, Component } from "vue-property-decorator";
import Breadcrumb from "@/components/home/Breadcrumb.vue";
import ProjectInfo from "@/components/assess/ProjectInfo.vue"

@Component({
    components: {
        "al-breadcrumb": Breadcrumb,
        ProjectInfo
    }
})
export default class Project_2021 extends Vue {
    earlyProjectData: any = {};
    projectData: any = {};
    projectShow:any[]=[
            "project_id",
            "item-contact",
            "item-company",
            "technology_name",
            "country_name",
            "technology_filed_name",
            "filed_self",
            "technology_introduction",
            "technology_advantage",
            "technology_stage_name",
            "technology_develop",
            "technology_sustainability",
            "technology_ip_name",
            "ip_owner_name",
            "ip_number",
            "ip_description",
            "technology_market",
            "technology_product_name",
            "product_description",
            "cooperation_type_name",
            "cooperation_description",
            "land_china_name",
            "financing",
            "project_comment",
        ]

    getEarlyProjectData() {
        this.earlyProjectData = this.$route.params;
    }

    getProjectData() {
        const getopt = this.$api.apiList.projectDetail_2021;
        const opt = getopt(this.earlyProjectData.pid);

        this.$axios({
            method: opt.method,
            url: opt.url,
            headers:opt.headers
        }).then((res: any) => {
            // console.log(res.data);
            this.projectData = res.data.data;
        });
    }

    mounted() {
        this.getEarlyProjectData();
        this.getProjectData();
    }
}
