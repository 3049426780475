



















































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Form, FormItem } from "element-ui";
import FormItemBox from "@/components/sign/FormItemBox.vue";
import { Getter } from "vuex-class";
@Component({
    components: {
        "el-form": Form,
        "al-form-item": FormItemBox,
    },
})
export default class CommentForm extends Vue {
    @Prop({ type: Number, required: true, default: () => 0 })
    remaining: any;

    @Getter("getUser") getUser: any;

    isshow: boolean = true;
    disabled = false; //提交按钮是否disabled
    isLoading = false; //提交按钮是否loading

    childName = "commentForm"; //form名
    formItem_class_basic = "commentForm"; //formItemBox class名基础部分

    /* 表单数据 */
    formData = { comment_root: "" };

    /**表单参数 */
    get formOpt() {
        let opt = [
            {
                ref: "comment_root",
                formkey: "comment_root",
                type: "input",
                classname: `${this.formItem_class_basic}-comment_root`,
                placeholder: `请输入评论内容`,
            },
        ];
        return opt;
    }

    /* 邮箱格式验证 */
    private valiCommentCodeNum(rule: any, value: any, callback: any) {
        String.prototype.trim = function () {
            return this.replace(/^\s\s*/, "").replace(/\s\s*$/, "");
        };

        if (value === "") {
            callback(new Error("不能为空"));
        } else if (value.trim().length < 5)
            callback(new Error("评论至少为 5 个字符。"));
        else {
            callback();
        }
    }

    /* 表单验证规则 */
    get formRules() {
        let opt = {
            comment_root: [
                {
                    required: true,
                    message: "不能为空",
                    trigger: "change",
                },
                { validator: this.valiCommentCodeNum, trigger: "blur" },
            ],
        };
        return opt;
    }

    submit() {
        if (this.getUser("id")) {
            if (this.disabled) return;
            else {
                (this.$refs[this.childName] as any).validate((valid:any) => {
                    if (valid) {
                        this.disabled = true;
                        this.isLoading = true;
                        this.$emit(
                            "sub",
                            this.formData.comment_root,
                            (res: any) => {
                                if (res.status === 0) {
                                    (
                                        this.$refs[this.childName] as any
                                    ).resetFields();
                                    this.$notify.success({
                                        title: `success`,
                                        message: `success`,
                                    });
                                } else {
                                    this.$notify.error({
                                        title: `error:${res.status}`,
                                        message: `${res.msg}`,
                                    });
                                }
                                this.isLoading = false;
                                this.disabled = false;
                            }
                        );
                    } else {
                        console.log("error submit!!");
                        return false;
                    }
                });
            }
        } else {
            this.$router.push({name:"login",params:{from:this.$route.path}})
        }
    }
}
