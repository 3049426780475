





































































import { Vue, Component } from "vue-property-decorator";
import Breadcrumb from "@/components/home/Breadcrumb.vue";
@Component({
    components: {
        "al-breadcrumb": Breadcrumb,
    },
})
export default class Expert_2021 extends Vue {
    earlyExpertData: any = {};
    expertData: any = {};

    loading1: boolean = true;
    getEarlyExpertData() {
        // console.log(this.earlyProjectData)
        this.earlyExpertData = this.$route.params;
    }
    getProjectData() {
        const getopt = this.$api.apiList.expertDetail_2021;
        const opt = getopt(this.earlyExpertData.eid);

        this.$axios({
            method: opt.method,
            url: opt.url,
        }).then((res: any) => {
            // this.expertData =
            //     this.$i18n.locale == "en"
            //         ? res.data.data.expert_en
            //         : res.data.data.expert_cn;
            this.expertData =res.data.data;
            this.loading1 = false;
        });
    }

    mounted() {
        this.loading1 = true;
        this.getEarlyExpertData();
        this.getProjectData();
    }
}
